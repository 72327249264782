import React, { useEffect, useState } from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import DensitySmall from '@mui/icons-material/DensitySmall';
import { useLocation, useNavigate } from 'react-router';
import { Signin } from '../iam/Signin';
import { Typography } from '@mui/material';
import { auth } from './../firebase-config';
import { useAuth } from '../hooks/useAuth';
import { ArrowBackIos, ArrowBackIosNew, Logout } from '@mui/icons-material';
import { Tooltip } from '@mui/joy';

export function Header(props: { children: React.ReactNode }) {
  const buttonRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row-reverse"
      >
        <Tooltip title="Logout" variant="outlined">
          <IconButton
            ref={buttonRef}
            id="apps-menu-demo"
            aria-label="Logout"
            aria-controls={'apps-menu'}
            aria-haspopup="true"
            variant="plain"
            color="info"
            sx={{ borderRadius: 90, margin: '10px' }}
            onClick={() => auth.signOut()}
          >
            <Logout />
          </IconButton>
        </Tooltip>
        <img
          style={{ margin: '20px' }}
          src={require('../logo.png')}
          height="45px"
        ></img>
        <Box display="flex" alignItems="center" minWidth="60px">
          {location.pathname != '/' && (
            <Tooltip title="Back" variant="outlined">
              <IconButton
                ref={buttonRef}
                id="apps-menu-demo"
                aria-label="Back"
                aria-controls={'apps-menu'}
                aria-haspopup="true"
                variant="plain"
                color="info"
                sx={{ borderRadius: 90, margin: '10px' }}
                onClick={() => navigate('/')}
              >
                <ArrowBackIosNew />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {props.children}
    </>
  );
}
