import { User, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth } from '../firebase-config';

export function useAuth(): [User | undefined, boolean] {
  const [user, setUser] = useState<User>();
  const [authenticated, setAuthenticated] = useState<boolean>(
    localStorage.getItem('isAuthenticated') === 'true'
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
      } else {
        setUser(undefined);
        setAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
      }
    });
    return () => unsubscribe();
  }, []);

  return [user, authenticated];
}
