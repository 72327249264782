import React, { useEffect } from 'react';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
import { auth } from './../firebase-config';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router';

export function AuthGoogle() {
  const [user, authenticated] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    } else {
      const ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(auth);
      ui.start('.firebase-auth-container', {
        signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true
          }
        ],
        signInSuccessUrl: '/'
      });
      return () => ui.reset();
    }
  }, [authenticated]);
  return (
    <div
      className="firebase-auth-container"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px'
      }}
    ></div>
  );
}
