import React from 'react';
import { Navigate } from 'react-router';

export function ProtectedRoute(props: {
  isAuhenticated: boolean;
  children: React.ReactElement;
  redirectPath?: string;
}): React.ReactElement {
  const path = props.redirectPath || '/sign-in';

  if (!props.isAuhenticated) {
    return <Navigate to={path} replace />;
  }
  return props.children;
}
