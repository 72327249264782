import React, { useEffect, useState } from 'react';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/joy/Card';
import axios from 'axios';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/joy/Typography';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import { Experience } from '../types/Experience';
import useRequests from '../hooks/useRequests';
import {
  Chip,
  Input,
  Select,
  Option,
  Modal,
  Sheet,
  ModalClose,
  Box,
  Checkbox
} from '@mui/joy';

const months = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
];

const years = Array.from({ length: 43 }, (v, k) => k + 1).map((i) => 1980 + i);

export function ExperienceView() {
  const [open, setOpen] = React.useState(false);
  const [userExperience, setUserExperience] = React.useState<Experience[]>([]);
  const [form, setForm] = React.useState({});
  const [config, isTokenReady] = useRequests();
  useEffect(() => {
    if (isTokenReady) {
      axios.get('/experience/list', config).then((res) => {
        setUserExperience(res.data);
      });
    }
  }, [config, isTokenReady]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    axios.post('/experience', form, config).then((res) => {
      setUserExperience(res.data);
    });
  };

  const deleteExperience = (e: Experience) => {
    axios.delete('/experience/' + e.id, config).then((res) => {
      setUserExperience(res.data);
    });
  };

  const onCHangeHandler = (e: any) => {
    e &&
      e.target &&
      setForm((f) => ({
        ...f,
        [e.target.name]: e.target.value || e.target.checked
      }));
  };

  const selectorChangeHandler = (name: string, value: {} | null) => {
    setForm((f) => ({ ...f, [name]: value }));
  };

  const onSelectorClick = (e: any) => {
    setForm((f) => ({ ...f, ['present']: e.target.checked }));
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <h1>Work Experience</h1>
          <Button variant="outlined" onClick={handleClickOpen}>
            Add Experience
          </Button>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                maxWidth: 500,
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg'
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: 'calc(-1/4 * var(--IconButton-size))',
                  right: 'calc(-1/4 * var(--IconButton-size))',
                  boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                  borderRadius: '50%',
                  bgcolor: 'background.surface'
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <div>
                  <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                  >
                    Add your working experience.
                  </Typography>
                  <Typography
                    id="modal-title-h4"
                    level="body1"
                    textColor="inherit"
                    fontWeight="sm"
                    mb={1}
                  >
                    This helps to personalize your resume.
                  </Typography>
                </div>
                <Input
                  placeholder="Company"
                  required
                  variant="outlined"
                  name="company"
                  onChange={onCHangeHandler}
                />
                <Input
                  // margin="dense"
                  id="position"
                  placeholder="Position"
                  fullWidth
                  required
                  variant="outlined"
                  name="position"
                  onChange={onCHangeHandler}
                />
                <Input
                  id="location"
                  placeholder="Location"
                  fullWidth
                  required
                  variant="outlined"
                  name="location"
                  onChange={onCHangeHandler}
                />
                <div style={{ display: 'flex', gap: '10px', height: '100%' }}>
                  <Select
                    id="outlined-select-start-moth"
                    placeholder="Start Month"
                    variant="outlined"
                    name="startMonth"
                    onChange={(e, newValue) =>
                      selectorChangeHandler('startMonth', newValue)
                    }
                    style={{ width: '50%' }}
                  >
                    {months.map((month) => (
                      <Option key={month.value} value={month.value}>
                        {month.label}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    id="outlined-select-start-moth"
                    placeholder="Start Year"
                    variant="outlined"
                    name="startYear"
                    onChange={(e, newValue) =>
                      selectorChangeHandler('startYear', newValue)
                    }
                    style={{ width: '50%' }}
                  >
                    {years.map((y) => (
                      <Option key={y} value={y}>
                        {y}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Select
                    id="outlined-select-start-moth"
                    placeholder="End Month"
                    variant="outlined"
                    name="endMonth"
                    onChange={(e, newValue) =>
                      selectorChangeHandler('endMonth', newValue)
                    }
                    style={{ width: '50%' }}
                  >
                    {months.map((month) => (
                      <Option key={month.value} value={month.value}>
                        {month.label}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    id="outlined-select-start-moth"
                    placeholder="End Year"
                    variant="outlined"
                    name="endYear"
                    onChange={(e, newValue) =>
                      selectorChangeHandler('endYear', newValue)
                    }
                    style={{ width: '50%' }}
                  >
                    {years.map((y) => (
                      <Option key={y} value={y}>
                        {y}
                      </Option>
                    ))}
                  </Select>
                </div>
                <Checkbox
                  label="Present"
                  name="present"
                  onChange={onCHangeHandler}
                />
                <Box
                  sx={{
                    mt: 1,
                    display: 'flex',
                    gap: 1,
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                      justifyContent: 'center'
                    }
                  }}
                >
                  <Button
                    variant="outlined"
                    color="neutral"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button variant="solid" color="neutral" onClick={handleSave}>
                    Submit
                  </Button>
                </Box>
              </div>
            </Sheet>
          </Modal>
        </div>
        <div style={{ display: 'flex', margin: '5%', gap: 20 }}>
          {userExperience.map((e) => (
            <Card
              key={e.id}
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '&:hover': {
                  boxShadow: 'md',
                  borderColor: 'neutral.outlinedHoverBorder'
                },
                gap: 0
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="info" gutterBottom>
                  {e.company}
                </Typography>
                <Typography level="h5" component="div">
                  {e.position}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="info">
                  {e.startMonth}/{e.startYear} -
                  {e.present === true
                    ? 'Present'
                    : `${e.endMonth}/${e.endYear}`}
                </Typography>
                <Chip
                  variant="outlined"
                  color="primary"
                  size="sm"
                  sx={{ pointerEvents: 'none' }}
                >
                  <Typography level="body2">{e?.location}</Typography>
                </Chip>
              </CardContent>
              <CardActions
                sx={{ justifyContent: 'space-around', gap: 1, padding: 0 }}
              >
                <Button
                  component="a"
                  variant="soft"
                  href={`/achievements/${e.id}`}
                  sx={{ gap: 1 }}
                >
                  <EmojiEventsTwoToneIcon color="warning" />
                  Add Achievement
                </Button>
                <Button variant="outlined" onClick={() => deleteExperience(e)}>
                  <DeleteTwoToneIcon color="error" />
                </Button>
              </CardActions>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}
