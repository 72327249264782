import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDLfHOq2UN6yFKL6ZuldOprmIG-5qeGFIY',
  authDomain: 'resumeai-8e2ce.firebaseapp.com',
  projectId: 'resumeai-8e2ce',
  storageBucket: 'resumeai-8e2ce.appspot.com',
  messagingSenderId: '1042174000187',
  appId: '1:1042174000187:web:5614d51f53a4a7ef3b301e',
  measurementId: 'G-C93DZFCFED'
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
