import React, { useEffect, useState } from 'react';
import { Router, BrowserRouter, Navigate } from 'react-router-dom';
import { Home } from './home/Home';
import { Skills } from './skills/Skills';
import { ExperienceView } from './experience/Experience';
import { Acheivements } from './achievements/Achievements';
import { PersoalInfo } from './iam/PersonalData';
import { Route, Routes } from 'react-router-dom';
import { Education } from './Education';
import { ProtectedRoute } from './router/ProtectedRoute';
import { useAuth } from './hooks/useAuth';
import { AuthGoogle } from './iam/AuthGoogle';
import { Header } from './layout/Header';
import { Resumes } from './resumes/Resumes';

function App(props: { isAuhenticated: boolean }) {
  const isAuhenticated = props.isAuhenticated;
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute isAuhenticated={isAuhenticated}>
                <>
                  <Header>
                    <Home />
                  </Header>
                </>
              </ProtectedRoute>
            }
          />
          <Route
            path="/skills"
            element={
              <ProtectedRoute isAuhenticated={isAuhenticated}>
                <Header>
                  <Skills />
                </Header>
              </ProtectedRoute>
            }
          />
          <Route
            path="/experience"
            element={
              <ProtectedRoute isAuhenticated={isAuhenticated}>
                <Header>
                  <ExperienceView />
                </Header>
              </ProtectedRoute>
            }
          />
          <Route
            path="/achievements/:experienceId"
            element={
              <ProtectedRoute isAuhenticated={isAuhenticated}>
                <Header>
                  <Acheivements />
                </Header>
              </ProtectedRoute>
            }
          />
          ,
          <Route path="/sign-in" element={<AuthGoogle />} />,
          <Route
            path="/personal-data"
            element={
              <ProtectedRoute isAuhenticated={isAuhenticated}>
                <Header>
                  <PersoalInfo />
                </Header>
              </ProtectedRoute>
            }
          />
          <Route
            path="/education"
            element={
              <ProtectedRoute isAuhenticated={isAuhenticated}>
                <Header>
                  <Education />
                </Header>
              </ProtectedRoute>
            }
          />
          <Route
            path="/resume-list"
            element={
              <ProtectedRoute isAuhenticated={isAuhenticated}>
                <Header>
                  <Resumes />
                </Header>
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export function AuthApp() {
  const [user, isAuhenticated] = useAuth();
  return <App isAuhenticated={isAuhenticated} />;
}

export default AuthApp;
