import React, { useEffect, useState } from 'react';
import {
  Chip,
  LinearProgress,
  Avatar,
  Typography,
  useMediaQuery
} from '@mui/material/index';
import axios from 'axios';
import useRequests from '../hooks/useRequests';
import { Input } from '@mui/joy';

export function Skills() {
  const [nonSelectedSkills, setNonSelectedSkills] = useState<
    Record<string, Skill[]>
  >({});
  const [availableSkills, setAvailableSkills] = useState<
    Record<string, Skill[]>
  >({});
  const [userSkills, setUserSkills] = useState<Skill[]>([]);

  const [config, isTokenReady] = useRequests();
  const [seaerchKeyword, setSearchKeyword] = useState<string>();
  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    let init: Record<string, Skill[]> = {};
    setNonSelectedSkills(
      Object.entries(availableSkills)
        .map((skill) => {
          return [
            skill[0],
            skill[1].filter((s) => !userSkills.map((s) => s.id).includes(s.id))
          ];
        })
        .reduce((acc, curr) => {
          acc[curr[0] as string] = curr[1] as Skill[];
          return acc;
        }, init)
    );
  }, [userSkills]);

  useEffect(() => {
    if (isTokenReady) {
      axios.get('/skills', config).then((res) => {
        setUserSkills(res.data);
      });
    }
  }, [config, isTokenReady, seaerchKeyword]);

  useEffect(() => {
    if (isTokenReady) {
      const searchParameter = seaerchKeyword ? '?search=' + seaerchKeyword : '';
      axios.get('/skills-all' + searchParameter, config).then((res) => {
        setAvailableSkills(res.data);
      });
    }
  }, [config, isTokenReady, seaerchKeyword]);

  const onDelete = (skill: Skill) => {
    axios
      .delete('/skills/' + btoa(skill.id), config)
      .then((res) => setUserSkills(res.data))
      .catch((err) => alert(err));
  };

  const addSkill = (skill: Skill) => {
    if (userSkills.find((s) => s.id === skill.id)) {
      return;
    }
    axios.post('/skills/' + btoa(skill.id), {}, config).then((res) => {
      setUserSkills(res.data);
    });
  };

  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          alignItems: 'center',
          marginBottom: '10px',
          width: matches ? '60%' : '100%'
        }}
      >
        <h1>Your Skillset</h1>
        <Typography variant="subtitle1" textAlign="center">
          You can manage your skills here. These skills will be used to generate
          a perfect resume
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <SkillChips
            skills={userSkills}
            title="Selected Skills"
            onDelete={onDelete}
            color="primary"
            largeTitle
          />
        </div>
        <div style={{ width: '100%' }}>
          <Input
            placeholder="Search"
            variant="plain"
            size="sm"
            sx={{ marginLeft: '5%', width: 'fit-content' }}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {Object.entries(nonSelectedSkills).length == 0 ? (
            <LinearProgress />
          ) : (
            Object.entries(nonSelectedSkills).map((e) => (
              <SkillChips
                outlined
                skills={e[1]}
                title={e[0]}
                onClick={addSkill}
                color="primary"
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
type SkillChipsProps = {
  skills: Skill[];
  title: string;
  color: 'primary' | 'info' | 'primary';
  largeTitle?: boolean;
  outlined?: boolean;
  onClick?: (skill: Skill) => void;
  onDelete?: (Skill: Skill) => void;
};

function SkillChips(props: SkillChipsProps) {
  return (
    <>
      {props.largeTitle ? <h2>{props.title}</h2> : <h4>{props.title}</h4>}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {props.skills.map((skill) => (
          <Chip
            key={skill.id}
            label={skill.name}
            variant={props.outlined ? 'outlined' : undefined}
            onClick={() => props.onClick?.(skill)}
            onDelete={
              props.onDelete ? () => props.onDelete?.(skill) : undefined
            }
            avatar={<Avatar>{skill.name.charAt(0).toUpperCase()}</Avatar>}
            color={props.color}
          />
        ))}
      </div>
    </>
  );
}

export type Skill = {
  id: string;
  name: string;
};
