import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import { Header } from '../layout/Header';
import axios from 'axios';
import useRequests from '../hooks/useRequests';
import UserStatusTab from './UserStatusTab';
import {
  Box,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Textarea,
  Tooltip,
  Typography
} from '@mui/joy';
import { FormatBold, KeyboardArrowDown } from '@mui/icons-material';

export function Home() {
  const [descriptionInput, setDescriptionInput] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [config] = useRequests();
  const [resumeGeneratorType, setResumeGeneratorType] = useState<
    'Link' | 'Description'
  >('Link');

  useEffect(() => {
    setDescriptionInput('');
  }, [resumeGeneratorType]);

  const generateResume = (withoutDescription?: boolean) => {
    setLoading(true);
    let params;
    let endpointPath;
    if (withoutDescription) {
      params = new URLSearchParams([]);
      endpointPath = 'generator/generate-resume';
    } else {
      params =
        resumeGeneratorType == 'Link'
          ? new URLSearchParams([['jobDescriptionURL', descriptionInput || '']])
          : new URLSearchParams([['description', descriptionInput || '']]);
      endpointPath =
        resumeGeneratorType == 'Link'
          ? 'generator/generate-resume/jop-posting-url'
          : '/generator/generate-resume/description';
    }
    axios
      .get(endpointPath, {
        ...config,
        params,
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.response);
        setLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <UserStatusTab></UserStatusTab>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            height: '100%',
            gap: '15px',
            alignItems: 'center'
          }}
        >
          <h1> Resume Generator</h1>
          <p style={{ textAlign: 'center' }}>
            Enter a link or copy & paste a job descriptions for a role you
            interested in and resume tailored for the role will be generated!
          </p>
          <LinearProgress
            determinate={!loading}
            variant="soft"
            sx={{ width: '80%' }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <RadioGroup
              orientation="horizontal"
              aria-labelledby="segmented-controls-example"
              name="Link"
              value={resumeGeneratorType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setResumeGeneratorType(event.target.value as any)
              }
              sx={{
                minHeight: 48,
                padding: '4px',
                borderRadius: '12px',
                bgcolor: 'neutral.softBg',
                '--RadioGroup-gap': '4px',
                '--Radio-actionRadius': '8px'
              }}
            >
              {[
                {
                  type: 'Link',
                  tooltipInfo:
                    'Easily create a tailored resume in seconds by pasting the link to the job description'
                },
                {
                  type: 'Desciption',
                  tooltipInfo:
                    'Copy and paste the job description to instantly generate your customized resume'
                }
              ].map((item) => (
                <Tooltip
                  enterDelay={500}
                  id={item.type}
                  title={item.tooltipInfo}
                  variant="plain"
                  size="lg"
                  placement="top"
                >
                  <Radio
                    key={item.type}
                    color="neutral"
                    value={item.type}
                    disableIcon
                    label={item.type}
                    variant="plain"
                    sx={{
                      px: 2,
                      alignItems: 'center'
                    }}
                    slotProps={{
                      action: ({ checked }) => ({
                        sx: {
                          ...(checked && {
                            bgcolor: 'background.surface',
                            boxShadow: 'sm',
                            '&:hover': {
                              bgcolor: 'background.surface'
                            }
                          })
                        }
                      })
                    }}
                  />
                </Tooltip>
              ))}
            </RadioGroup>
          </Box>
          <Textarea
            id="outlined-basic"
            value={descriptionInput}
            placeholder={
              resumeGeneratorType == 'Link'
                ? 'Enter a link to the job description'
                : 'Copy & Paste job description'
            }
            variant="outlined"
            onChange={(e) => setDescriptionInput(e.target.value)}
            minRows={resumeGeneratorType == 'Link' ? 1 : 16}
            sx={{ width: '100%' }}
            endDecorator={
              <Box
                sx={{
                  display: 'flex',
                  gap: 'var(--Textarea-paddingBlock)',
                  pt: 'var(--Textarea-paddingBlock)',
                  borderColor: 'divider',
                  justifyContent: 'flex-end',
                  width: '100%'
                }}
              >
                <Button
                  loading={loading}
                  size="sm"
                  variant="soft"
                  onClick={() => generateResume()}
                >
                  Generate Resume
                </Button>
                <Button
                  variant="soft"
                  color="danger"
                  size="sm"
                  onClick={() => setDescriptionInput('')}
                >
                  Clear
                </Button>
              </Box>
            }
          />
          <Button variant="plain" onClick={() => generateResume(true)}>
            Generate Resume without Job description
          </Button>
        </div>
      </div>
    </>
  );
}
