import { useActionData } from 'react-router';
import { useAuth } from './useAuth';
import { useEffect, useState } from 'react';
import RawAxiosRequestConfig from 'axios';
import { onIdTokenChanged } from 'firebase/auth';
import { auth } from '../firebase-config';

export default function useRequests(): [any, boolean] {
  const [user, authenticated] = useAuth();
  const [tokenIsAvailable, setTokenIsAvailable] = useState(false);
  const [config, setConfig] = useState<any>({
    baseURL: process.env.REACT_APP_BACKEND_URL
  });

  useEffect(() => {
    if (user) {
      user.getIdToken().then((token) => {
        setConfig((prev: { headers: any }) => {
          const headers = { ...(prev.headers || {}), Authorization: token };
          return { ...prev, headers };
        });
        setTokenIsAvailable(true);
      });
    } else {
      setTokenIsAvailable(false);
    }
  }, [user, authenticated]);

  useEffect(() => {
    onIdTokenChanged(auth, (user) => {
      user?.getIdToken().then((token) => {
        setConfig((prev: { headers: any }) => {
          const headers = { ...(prev.headers || {}), Authorization: token };
          return { ...prev, headers };
        });
        setTokenIsAvailable(true);
      });
    });
  }, []);
  return [config, tokenIsAvailable];
}
