import { useEffect, useState } from 'react';
import useRequests from '../hooks/useRequests';
import axios from 'axios';
import {
  Box,
  Button,
  LinearProgress,
  Link,
  Select,
  Table,
  Textarea,
  Typography
} from '@mui/joy';
import Option from '@mui/joy/Option';

type Resume = {
  companyName: string;
  position: string;
  resumestatus: string;
  id: string;
  filename: string;
  timestamp: number;
  username: string;
  jobDescription?: string;
};
const ResumeStatus = [
  'GENERATED',
  'APPLIED',
  'REJECTED',
  'INTERVIEWING',
  'OFFER',
  'OFFER_ACCEPTED'
] as const;

export function Resumes() {
  const [resumeList, setResumeList] = useState<Resume[]>();
  const [config, isTokenReady] = useRequests();
  const [dirtyResumes, setDirtyResumes] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isTokenReady) {
      axios
        .get('resume/list', config)
        .then((res) => {
          setResumeList(res.data);
        })
        .catch((err) => {
          alert(JSON.stringify(err));
        });
    }
  }, [isTokenReady]);

  const onDelete = (id: string) => {
    axios
      .delete('resume/' + id, config)
      .then((res) => setResumeList(res.data))
      .catch((err) => alert(JSON.stringify(err)));
  };
  const onLinkClick = (fileName: string) => {
    setLoading(true);
    var params = new URLSearchParams({ fileName });
    axios
      .get('resume/get-file', {
        ...config,
        params,
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.response);
      });
  };

  const updateField = (
    resume: Resume,
    newValue: string | null,
    propertyName: string
  ) => {
    const updatedResume = { ...resume, [propertyName]: newValue };

    setResumeList((resumeList) => {
      const filteredResume =
        resumeList?.filter((resume) => resume.id !== updatedResume.id) || [];
      const updatedList = [...filteredResume, updatedResume].sort(
        (a, b) => a.timestamp - b.timestamp
      );
      return updatedList;
    });
    setDirtyResumes((dirtyResumes) => dirtyResumes.add(resume.id));
  };

  const updateResume = (resume: Resume) => {
    axios
      .put('/resume', resume, config)
      .then((res) => {
        setResumeList(res.data);
        setDirtyResumes((dirtyResumes) => {
          dirtyResumes.delete(resume.id);
          return dirtyResumes;
        });
      })
      .catch((err) => {
        alert(JSON.stringify(err.message));
      });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography level="h2" sx={{ m: 1 }}>
        My Resumes
      </Typography>
      <Typography
        level="body1"
        sx={{ mx: 3, mb: 2, mt: 2, textAlign: 'center' }}
      >
        You can review each resume, examine the job description that was used as
        the basis for generating the resume, and include additional details such
        as the company name, position applied for, or the current status of the
        job application
      </Typography>
      <LinearProgress
        determinate={!loading}
        variant="soft"
        sx={{ width: '80%' }}
      />
      <Box
        sx={{
          '& thead th:nth-child(5)': { width: '25%' },
          '& thead th:nth-child(2)': { width: '25%' },
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '80%'
        }}
      >
        <Table>
          <thead>
            <tr>
              <th>Сompany</th>
              <th>Position</th>
              <th>Status</th>
              <th>Date created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {resumeList?.map((row) => (
              <tr>
                <td>
                  <Textarea
                    value={row.companyName}
                    placeholder="Empty"
                    onChange={(e) => {
                      updateField(row, e.target.value, 'companyName');
                    }}
                  />
                </td>
                <td>
                  <Textarea
                    value={row.position}
                    placeholder="Empty"
                    onChange={(e) => {
                      updateField(row, e.target.value, 'position');
                    }}
                  />
                </td>
                <td>
                  <Select
                    defaultValue={row.resumestatus}
                    onChange={(e, value) => {
                      updateField(row, value, 'resumestatus');
                    }}
                  >
                    {ResumeStatus.map((status) => (
                      <Option value={status}>{status}</Option>
                    ))}
                  </Select>
                </td>
                <td>{new Date(row.timestamp).toLocaleDateString()}</td>
                <td>
                  <Box sx={{ display: 'flex', gap: '3px' }}>
                    {dirtyResumes.has(row.id) && (
                      <Button
                        variant="soft"
                        size="sm"
                        onClick={() => updateResume(row)}
                      >
                        Save Update
                      </Button>
                    )}
                    {row.jobDescription && (
                      <Link
                        target="_blank"
                        rel="noreferrer"
                        href={row.jobDescription}
                      >
                        <Button variant="outlined" size="sm">
                          Open Job Description
                        </Button>
                      </Link>
                    )}
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={() => onLinkClick(row.filename)}
                    >
                      View PDF
                    </Button>
                    <Button
                      size="sm"
                      variant="outlined"
                      onClick={() => onDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
}
