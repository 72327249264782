import { useEffect, useState } from 'react';
import { Header } from './layout/Header';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import axios from 'axios';
import useRequests from './hooks/useRequests';

type Form = {
  schoolName: string;
  degreeTitle: string;
  startDate: string;
  endDate: string;
  location: string;
};

export function Education() {
  const [education, sedEducation] = useState<Form>({
    schoolName: '',
    degreeTitle: '',
    startDate: '',
    endDate: '',
    location: ''
  });

  const [config, isTokenReady] = useRequests();

  useEffect(() => {
    if (isTokenReady) {
      axios
        .get('/education', config)
        .then((response) => {
          if (response) sedEducation(response.data);
        })
        .catch((err) => {
          alert(err);
        });
    }
  }, [config, isTokenReady]);

  const applyForm = () => {
    axios
      .post('/education', education, config)
      .then((res) => {
        alert('Saved');
      })
      .catch((err) => alert(err.response.data.description));
  };
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1>Education</h1>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20
          }}
        >
          <Input
            sx={TextFieldStyle}
            value={education.schoolName}
            placeholder="School Name"
            variant="outlined"
            onChange={(e) =>
              sedEducation({ ...education, schoolName: e.target.value })
            }
          />
          <Input
            sx={TextFieldStyle}
            value={education.degreeTitle}
            placeholder="Degree Title"
            variant="outlined"
            onChange={(e) =>
              sedEducation({ ...education, degreeTitle: e.target.value })
            }
          />
          <Input
            sx={TextFieldStyle}
            value={education.startDate}
            placeholder="Start Date"
            variant="outlined"
            onChange={(e) =>
              sedEducation({ ...education, startDate: e.target.value })
            }
          />
          <Input
            sx={TextFieldStyle}
            value={education.endDate}
            placeholder="End Date"
            variant="outlined"
            onChange={(e) =>
              sedEducation({ ...education, endDate: e.target.value })
            }
          />
          <Input
            sx={TextFieldStyle}
            value={education.location}
            placeholder="Location"
            variant="outlined"
            onChange={(e) =>
              sedEducation({ ...education, location: e.target.value })
            }
          />

          <Button variant="solid" onClick={applyForm}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}
const TextFieldStyle = {
  width: '50%'
};
