import React, { useEffect, useState } from 'react';
import { Header } from '../layout/Header';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import useRequests from '../hooks/useRequests';
import { Box, FormHelperText, Stack } from '@mui/joy';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
type Form = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  location?: string;
  phoneNumber?: string;
  linkedIn?: string;
};

export function PersoalInfo() {
  const [userInfo, setUserInfo] = useState<Form>();

  const [config, isTokenReady] = useRequests();

  useEffect(() => {
    if (isTokenReady) {
      axios
        .get('/user?createIfNotExist=true', config)
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((err) => {
          alert(err.response.data.description);
        });
    }
  }, [config, isTokenReady]);

  const applyForm = (event: any) => {
    event.preventDefault();
    axios
      .post('/update-personal-data', userInfo, config)
      .then(() => {
        alert('Personal Info saved');
      })
      .catch((err) => alert(err.response.data.description));
  };
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1>Personal Info</h1>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20
          }}
        >
          <Stack spacing={1} sx={{ width: '50%' }}>
            <Input
              value={userInfo?.firstName}
              placeholder="First Name"
              variant="outlined"
              error={!!!userInfo?.firstName}
              required
              onChange={(e) =>
                setUserInfo({ ...userInfo, firstName: e.target.value })
              }
            />
            {!!!userInfo?.firstName && (
              <FormHelperText>
                <PriorityHighIcon />
                Location is required
              </FormHelperText>
            )}
          </Stack>
          <Stack spacing={1} sx={{ width: '50%' }}>
            <Input
              value={userInfo?.lastName}
              placeholder="Last Name"
              variant="outlined"
              required
              error={!!!userInfo?.lastName}
              onChange={(e) =>
                setUserInfo({ ...userInfo, lastName: e.target.value })
              }
            />
            {!!!userInfo?.lastName && (
              <FormHelperText>
                <PriorityHighIcon />
                Location is required
              </FormHelperText>
            )}
          </Stack>
          <Stack spacing={1} sx={{ width: '50%' }}>
            <Input
              value={userInfo?.email}
              placeholder="Email"
              variant="outlined"
              error={!!!userInfo?.email}
              required
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
            />
            {!!!userInfo?.email && (
              <FormHelperText>
                <PriorityHighIcon />
                Location is required
              </FormHelperText>
            )}
          </Stack>
          <Stack spacing={1} sx={{ width: '50%' }}>
            <Input
              value={userInfo?.location}
              placeholder="Location"
              variant="outlined"
              required
              error={!!!userInfo?.location}
              onChange={(e) =>
                setUserInfo({ ...userInfo, location: e.target.value })
              }
            />
            {!!!userInfo?.location && (
              <FormHelperText>
                <PriorityHighIcon />
                Location is required
              </FormHelperText>
            )}
          </Stack>
          <Stack spacing={1} sx={{ width: '50%' }}>
            <Input
              value={userInfo?.phoneNumber}
              placeholder="Phone Number"
              variant="outlined"
              error={!!!userInfo?.phoneNumber}
              required
              onChange={(e) =>
                setUserInfo({ ...userInfo, phoneNumber: e.target.value })
              }
            />
            {!!!userInfo?.phoneNumber && (
              <FormHelperText>
                <PriorityHighIcon />
                Phone Numer is required
              </FormHelperText>
            )}
          </Stack>
          <Stack spacing={1} sx={{ width: '50%' }}>
            <Input
              value={userInfo?.linkedIn}
              placeholder="LinkedIn"
              variant="outlined"
              required
              error={!!!userInfo?.linkedIn}
              onChange={(e) =>
                setUserInfo({ ...userInfo, linkedIn: e.target.value })
              }
            />
            {!!!userInfo?.linkedIn && (
              <FormHelperText>
                <PriorityHighIcon />
                LinkedIn is required
              </FormHelperText>
            )}
          </Stack>
          <Button variant="solid" onClick={applyForm} type="submit">
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}
const TextFieldStyle = {
  width: '50%'
};
